/* About.css */

.about-container {
    background-color: var(--main-background);
    padding-top: 10vh;
    min-height: 100vh;
    border: 1px solid var(--main-background);
}

.details-container {
    padding: 80px 0;
}

.container {
    margin: 0 auto;
    min-height: 100vh;
}


.row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.about-text {
    text-align: left;
}

.about-text p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
}



.slider-image {
    width: 100%;
    max-height: 500px;
    border-radius: 8px;
    overflow: hidden;
}

.service-header{
    font-family: 'Bree Serif', serif;
        color: #333;
        font-size: 2.5em;
        margin-bottom: 30px;
        text-align: center;
        color: var(--headings);
}


/* Add any additional styling as needed */
.feature-cards {
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 50vh;
}


@keyframes shake {

    0% {
            transform: translateX(0);
        }
    
        25% {
            transform: translateX(-5px) rotate(-5deg);
        }
    
        50% {
            transform: translateX(5px) rotate(5deg);
        }
    
        75% {
            transform: translateX(-5px) rotate(-5deg);
        }
    
        100% {
            transform: translateX(0);
        }
}

.feature-card {
    width: 250px;
    margin: 0 15px 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}
.feature-card:hover{
    animation: shake 0.5s ease ;
}

.card-icon {
    font-size: 34px;
    margin-bottom: 10px;
    color: var(--accent-background);
}

.card-text {
    font-size: 16px;
}

@media screen and (max-width:879px) {
    .feature-card{
        width: 45vw;
    }
}


@media screen and (max-width:768px) {
    .service-header {
        font-size: 24px;
    }

    .details-container {
        padding: 20px;
    }
}

@media screen and (max-width:400px) {
    .feature-card {
        width: 90vw;
    }
}