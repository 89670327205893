/* sidebar.css */

.sidebar {
    width: 300px;
    position: relative;
    background-color: #ffffff;
    transition: width 0.1s ease-in, width 0.1s linear;
    margin-top: 10vh;
    height: 90vh;
    overflow: auto;
}

.sidebar.collapsed {
    width: 0;
    overflow: hidden;
    transition: width 0.1s linear;
}

.hide-icons {
    width: 64px;
}

@media screen and (max-width:768px) {
    .sidebar {
        position: fixed;
        width: 250px;
    }

    .hide-icons {
        width: 0px;
        left: -60px;
    }
}



.border {
    border: 1px solid var(--border);
}

.d-flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.header-container {
    padding: 10px;
}

.logo-text {
    display: flex;
    align-items: center;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: var(--main-text);
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease, border-left 0.3s ease;
}

.sidebar-link:hover {
    background-color: var(--main-background);
    border-left: 6px solid var(--accent-background);
    color: var(--main-text);
}

.active {

    color: var(--button-text);
    background-color: var(--accent-background);
}

.sidebar-link div {
    display: flex;
    gap: 15px;
}

.p-2 {
    padding: 8px;
}

.btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    text-align: left;
}

.btn:hover {
    background-color: var(--background-hover);
}

.logout-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    text-decoration: none;
    color: var(--main-text);
}


.name {
    text-shadow: 4px 4px 4px #5c679b;
    font-style: italic;
    font-weight: bold;
    color: var(--accent-background);
}