body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Background Colors  */
  /* --background-white: #ffffff; */
  /* --main-background: #f9f9f9; */
  /* --main-background: #f1f2f6;
  --main-background-shade: rgb(218, 213, 252);
  --shadow:rgb(233, 230, 255);
  --accent-background: rgb(88, 53, 227);
  --page-background: rgb(242, 243, 248); */

  /* Text Colors  */
  /* --main-text: #333333;
  --text-subtle: #5c5c5c;
  --heading: #0f1238;
  --headings: rgb(88, 53, 227);
  --button-text: aliceblue;
  --main-link-text:rgb(88, 53, 227); */

   /* Border and Hover Colors  */
  /* --border: #dedede;
  --background-hover: #dedede;
  --button-hover: rgb(75, 7, 201); */
}



/* :root {
  --background-white: #ffffff;
  --main-background: #eaebf1;
  --main-background-shade: #cfd7ff;
  --accent-background: #0f1b52;
  --page-background: #3498db;

  --main-text: #333333;
  --text-subtle: #5c5c5c;
  --heading: #0f1238;
  --headings: #0f1a49;

  --button-text: aliceblue;
  --main-link-text: #0f1a49;
  
  --border: #dedede;
  --background-hover: #dedede;
  --button-hover: #0f1a49;

} */

/* 
:root {
  --background-white: #ffffff;
  --main-background: #eaebf1;
  --main-background-shade: #bdc2e0;
  --accent-background: #042e69;
  --page-background: #3498db;

  --main-text: #333333;
  --text-subtle: #5c5c5c;
  --heading: #0f1238;
  --headings: #042e69;

  --button-text: aliceblue;
  --main-link-text: #042e69;

  --border: #c0c9d2;
  --background-hover: #c0c9d2;
  --button-hover: #042e69;
} */


:root {
  --background-white: #ffffff;
  --main-background: #eaebf1;
  --main-background-shade: hwb(229 76% 8%);
  --accent-background: #042e69;
  --page-background: #3498db;

  --main-text: #333333;
  --text-light: #5c5c5c;
  --heading: #161b5d;
  --headings: #042e69;

  --button-text: aliceblue;
  --main-link-text: #042e69;

  --border: #c0c9d2;
  --background-hover: #c0c9d2;
  --button-hover: #042e69;

}