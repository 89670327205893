@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");

.navbar-container{
    padding: 0px 40px;
    min-height: 10vh;
    color: #ffffff;
    background-color: var(--accent-background);
    opacity: .94;
    width: 100%;
}

.navbar-container-login {
    padding: 0px 10px;
    min-height: 10vh;
    color: #ffffff;
    background-color: var(--accent-background);
}

.navbar-image {
    width: 40px;
    height: 40px;
}

.logo-image{
    height: 64px;
    width: 100px;
}

@media screen and (max-width:912px) {
    .btn{
        margin-bottom: 20px;
    }
        .logo-image {
            height: 45px;
            width: 70px;
        }
    .navbar-container{
        padding: 10px 0;
    }
}

.nav-link {
    position: relative;
    font-size: 15px;
    margin-right: 10px;
    border-radius: 4px;
    text-align: center;
    height: 100%;
    color: white;
}

.nav-link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;

    background-color: var(--background-white);
        transition: width .4s;
        color: var(--background-white);
}

.nav-link:hover::before {
    width: 100%;
    color: var(--background-white);
}

.auth-button{
    background-color: var(--main-background-shade);
    color: var(--accent-background);
    font-weight: 500;
    gap: 8px;
    height: 42px;
    border: none;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    transition: .3s;
    padding: 14px;
}

.three-dots{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    outline: none;
    border: 0;
    background-color: transparent;
    color: rgb(255, 255, 255)
}

.three-dots:hover{
    background-color: var(--button-hover);
}


.logo-link{
    text-decoration: none;
}

.active-nav{
    border-bottom:3px solid var(--background-white);
    color: var(--background-white);
    font-weight: 600;
}

.profile{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    color: aliceblue;
    margin-right: 10px;
    cursor: pointer;
} 

@media screen and (max-width:768px) {
    .profile{
        height: 28px;
        width: 28px;
        font-size: 12px;
    }
    .profile-name{
        font-size: 12px;
    }
    .name{
        margin: 0;
    }
}



