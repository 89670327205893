/* userProfile.css */


.main-container{
    width: 100%;
    /* border: 1px solid #ccc; */
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */

}
.user-profile-container{
    width: 400px;
}
@media screen and (max-width:500px) {
    .user-profile-container {
        width: 240px;
    }
}
@media screen and (max-width:350px) {
    .user-profile-container {
        width: 200px;
    }
}
.user-profile-item {
    margin-bottom: 10px;
    width: 100%;
}

.input-field {
    width: 100%;
    padding: 14px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
}

.user-profile-buttons {
    margin-top: 20px;
}



.edit-button {
    background-color: #4285f4;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
}

.apply-button {
    background-color: #34a853;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
}

.close-button {
    background-color: #6d6d6d;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
}