.contact-us-container{
    margin-top: 10vh;
    min-height: 90vh;
    display: flex;
    padding:80px 10px;
    background-color: var(--main-background);
}

.container-section{
    padding: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 30px rgb(191, 191, 191);
    border-radius: 8px;
}

.section-title{
    margin-left: 40px;
    color: var(--headings);
    font-family: 'Bree Serif';
    margin-bottom: 10px;
}

.section-description{
    margin-left: 40px;
    margin-bottom: 40px;
}

.button{
    padding: 10px 20px;
    background-color: #f8be42;
    color: white;
    font-weight: bolder;
}

.content{
    padding: 0 40px;
    font-size: 18px;
}

@media screen and (max-width:978px) {
    .content {
        padding: 0 20px;
        font-size: 16px;
    }
        .section-title {
            margin-left: 0px;
            color: var(--headings);
            font-family: 'Bree Serif';
        }
        .section-description{
            margin-left: 0px;
        }
        .contact-us-container{
            padding: 20px;
        }
}

@media screen and (max-width:768px) {
    .content{
        padding: 0;
    }
}

.form-control{
    padding: 12px;
}

.span-text{
    font-weight: bold;
    font-size: 18px;
    font-family: 'Bree Serif';
}